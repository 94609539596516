<template>
  <b-row>
    <b-col cols="12" sm="8">
      <b-card no-header>
        <template slot="header">
          Edit Account
        </template>
        <b-row class="form-group">
          <b-col md="6">
            <label>First Name</label>
            <b-form-input type="text" placeholder="First Name" v-model.trim="account.contact.firstName"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
          <b-col md="6">
            <label>Last Name</label>
            <b-form-input type="text" placeholder="Last Name" v-model.trim="account.contact.lastName"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
        </b-row>
        <b-row class="form-group">
          <b-col md="4">
            <label>E-Mail Address</label>
            <b-form-input type="text" placeholder="" v-model.trim="account.contact.emailAddress.address"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
          <b-col md="4">
            <label>Username</label>
            <b-form-input type="text" placeholder="Username" v-model.trim="account.username" autocomplete="off"></b-form-input>
            <b-form-invalid-feedback>This is a required field</b-form-invalid-feedback>
          </b-col>
          <b-col sm="4">
            <label>Organization</label>
            <b-form-select :options="organizations" v-model="account.organizationId" :disabled="!this.$can('manage', 'clientOrganization')"></b-form-select>
          </b-col>
        </b-row>
        <template slot="footer">
          <b-row>
            <b-col sm="6">
              <b-button variant="danger" @click="del">Delete</b-button>
            </b-col>
            <b-col sm="6" class="text-right">
              <b-button variant="primary" @click="save">Save</b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
    <b-col cols="12" sm="4">
      <b-card class="mb-3" no-body>
        <template slot="header">Roles</template>
        <b-list-group flush>
          <b-list-group-item href="#" @click="toggleRole(account, role)" :variant="hasRole(account, role) ? 'success' : ''" v-for="role in accountRoles" :key="`${account.id}-${role.id}`">
            <i class="fa" v-bind:class="{ 'fa-check-circle-o': hasRole(account, role), 'fa-circle-o': !hasRole(account, role) }"></i> {{ role.name }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
      <b-card no-header>
        <template slot="header">
          Update Password
        </template>
        <b-row class="form-group">
          <b-col md="12">
            <b-form-group>
              <b-input-group>
                <b-form-input type="password" autocomplete="off" v-model="password" placeholder="Password" :state="state($v.password)" @input="touch($v.password)"></b-form-input>
                <b-input-group-append><b-input-group-text><i class="fa fa-asterisk"></i></b-input-group-text></b-input-group-append>
                <b-form-invalid-feedback>Password needs to be 8 characters</b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <b-input-group>
                <b-form-input type="password" autocomplete="off" v-model="verifyPassword" placeholder="Verify" :state="state($v.verifyPassword)" @input="touch($v.verifyPassword)"></b-form-input>
                <b-input-group-append><b-input-group-text><i class="fa fa-asterisk"></i></b-input-group-text></b-input-group-append>
                <b-form-invalid-feedback>Passwords should match</b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <template slot="footer">
          <b-row>
            <b-col sm="12" class="text-right">
              <b-button :disabled="$v.password.$invalid || $v.verifyPassword.$invalid" variant="primary" @click="savePassword">Save Password</b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'

export default {
  name: 'Account',
  props: {
    caption: {
      type: String,
      default: 'Account id'
    },
  },
  computed: {
    ...mapGetters({
      currentAccount: 'global/account',
      roles: 'global/roles',
    }),
    accountRoles () {
      const roles = []
      const myRoles = []
      let minSort = null

      if (this.currentAccount) {
        this.currentAccount.roles.forEach((role) => {
          myRoles.push(role.id)
          if (!minSort || minSort > role.sort) {
            minSort = role.sort
          }
        })
      }

      if (this.currentAccount && this.roles) {
        this.roles.forEach((role) => {
          if ((minSort !== null && (role.sort > minSort || minSort === 1)) || myRoles.includes(role.id)) {
            roles.push(role)
          }
        })
      }

      return roles.sort((a, b) => {
        if (a.sort > b.sort) return 1
        else if (a.sort < b.sort) return -1
        return 0
      })
    }
  },
  data: () => {
    return {
      organizations: [
        { value: null, text: 'Select Organization...', disabled: true }
      ],
      account: {
        contact: {
          firstName: null,
          lastName: null,
          emailAddress: {
            address: null
          }
        },
        username: null,
        roles: []
      },
      password: null,
      verifyPassword: null
    }
  },
  methods: {
    toggleRole (account, role) {
      if (this.hasRole(account, role)) {
        this.$api.accounts.removeRole({ accountId: account.id, roleId: role.id })
          .then((response) => {
            this.fetchAccount()
          })
          .catch((err) => {
            this.$snotify.error('Error Removing Role')
          })
      } else {
        this.$api.accounts.addRole({ accountId: account.id, roleId: role.id })
          .then((response) => {
            this.fetchAccount()
          })
          .catch((err) => {
            this.$snotify.error('Error Adding Role')
          })
      }
    },
    hasRole (account, role) {
      if (account) {
        return account.roles.map(o => o.id).includes(role.id)
      }
    },
    state (vField) {
      if (typeof(vField) != 'undefined') {
        return vField.$dirty ? !vField.$invalid : null
      }
    },
    touch (vField) {
      if (typeof(vField) != 'undefined') {
        vField.$touch()
      }
    },
    savePassword () {
      this.$api.accounts.update(this.$route.params.id, { password: this.password })
        .then((response) => {
          this.$snotify.success('Password Updated')
          this.password = null
          this.verifyPassword = null
          this.$nextTick(() => { this.$v.$reset() })
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Password')
        })
    },
    goBack() {
      this.$router.go(-1)
      // this.$router.replace({path: '/users'})
    },
    del () {
      this.$api.accounts.delete(this.$route.params.id)
        .then((response) => {
          this.$router.push({path: '/settings/accounts'})
        })
        .catch((err) => {
          this.$snotify.error('Error Deleting Account')
        })
    },
    save () {
      this.$api.accounts.update(this.$route.params.id, this.account)
        .then((response) => {
          this.$snotify.success('Account Saved Successfully')
          this.account = response.data
          this.$nextTick(() => { this.$v.$reset() })
        })
        .catch((err) => {
          this.$snotify.error('Error Saving Account')
        })
    },
    fetchAccount () {
      this.$api.accounts.get(this.$route.params.id)
        .then((response) => {
          this.account = response.data
        })
        .catch((err) => {
          this.$snotify.error('Error Fetching Account')
        })
    }
  },
  created () {
    this.fetchAccount()

    this.$store.dispatch('global/getRoles')

    this.$api.accounts.organizations.get()
      .then((response) => {
        response.data.forEach((organization) => {
          this.organizations.push({ value: organization.id, text: organization.name })
        })
      })
      .catch((err) => {
        console.log(err)
      })
  },
  validations: {
    password: {
      required,
      minLength: minLength(8)
    },
    verifyPassword: {
      required,
      sameAsPassword: sameAs('password')
    }
  }
}
</script>
